<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<CrudToolbar @create="openNew" @delete="onDeleteEntities" @export="exportCSV($event)"/>
				<DataTable ref="dt" :value="entities" v-model:selection="selectedEntities" v-model:expandedRows="expandedRows" dataKey="id"
							:showGridlines=true
							filterDisplay="menu"
							v-model:filters="filters"
							:globalFilterFields="['code','type','name','address','contactName', 'contactPhone', 'contactEmail']"
							:paginator="true" :rows="10"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} partners" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<!--
							<h5 class="m-0">{{$t('partner.list_title')}}</h5>
							-->
							<Button type="button" icon="pi pi-filter-slash" :label="$t('common.clear')" class="p-button-outlined" @click="clearFilters()"/>
							<IconField iconPosition="left">   
								<InputIcon>
									<i class="pi pi-search" />
								</InputIcon>             
								<InputText v-model="filters['global'].value" placeholder="Search..." />        
							</IconField>
							<!--
							<span class="block mt-2 md:mt-0 p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Search..." />
							</span>
							-->
						</div>
					</template>
					<!-- expand each row -->
					<!--
					<Column :expander="true" headerStyle="width: 3rem" />
					-->
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column headerStyle="min-width:10rem;" bodyStyle="text-align:center;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editEntity(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
						</template>
					</Column>
					<Column field="code" :header="$t('partner.code')" :sortable="true" headerStyle="width:5%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.code')}}</span>
							{{slotProps.data.code}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.code')])"/>
						</template>
					</Column>
					<Column field="type" :header="$t('partner.partnerType')" :sortable="true" headerStyle="width:6%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.partnerType')}}</span>
							{{slotProps.data.partnerType}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.type')])"/>
						</template>
					</Column>
					<Column field="name" :header="$t('partner.name')" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.name')}}</span>
							{{slotProps.data.name}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.name')])"/>
						</template>
					</Column>
					<Column field="country" :header="$t('partner.country')" :sortable="true" headerStyle="width:6%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.country')}}</span>
							{{slotProps.data.country}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.country')])"/>
						</template>
						</Column>
						<Column field="state" :header="$t('partner.state')" :sortable="true" headerStyle="width:8%; min-width:6rem;">
							<template #body="slotProps">
								<span class="p-column-title">{{$t('partner.state')}}</span>
								{{slotProps.data.state}}
							</template>
							<template #filter="{filterModel}">
								<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.state')])"/>
							</template>
					</Column>
					<Column field="address" :header="$t('partner.address')" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.address')}}</span>
							{{slotProps.data.address}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.address')])"/>
						</template>
						</Column>
						<Column field="contactName" :header="$t('partner.contactName')" :sortable="true" headerStyle="width:20%; min-width:10rem;">
							<template #body="slotProps">
								<span class="p-column-title">{{$t('partner.contactName')}}</span>
								{{slotProps.data.contactName}}
							</template>
							<template #filter="{filterModel}">
								<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.contactName')])"/>
							</template>
					</Column>
					<Column field="contactPhone" :header="$t('partner.contactPhone')" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.contactPhone')}}</span>
							{{slotProps.data.contactPhone}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.contactPhone')])"/>
						</template>
						</Column>
						<Column field="contactEmail" :header="$t('partner.contactEmail')" :sortable="true" headerStyle="width:20%; min-width:10rem;">
							<template #body="slotProps">
								<span class="p-column-title">{{$t('partner.contactEmail')}}</span>
								{{slotProps.data.contactEmail}}
							</template>
							<template #filter="{filterModel}">
								<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('partner.contactEmail')])"/>
							</template>
					</Column>
					<Column field="description" :header="$t('partner.description')" :sortable="true" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('partner.description')}}</span>
							{{slotProps.data.description}}
						</template>
					</Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<h5>{{slotProps.data.code}}</h5>
						</div>
					</template>
				</DataTable>

				<DialogFormPartner :entity="currentEntity" :visible="entityDialog"
					@hide="entityDialog=false"
					@cancel="entityDialog=false"
					@save="saveEntity($event)"/>

				<DialogConfirm :visible="deleteEntityDialog"
					:header="$t('partner.confirm_delete')"
					content = "Dialog content"
					@hide="deleteEntityDialog = false"
					@reject="deleteEntityDialog = false" @accept="deleteEntity"
				>
					<template #content>
						<div class="flex align-items-center justify-content-center">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span v-if="currentEntity">Are you sure you want to delete <b>{{currentEntity.name}}</b>?</span>
						</div>
					</template>
				</DialogConfirm>
			</div>
		</div>
	</div>

</template>

<script>
//import {FilterMatchMode} from 'primevue/api';
import PartnerService from '@/service/PartnerService'
import DialogConfirm from '@/pages/common/DialogConfirm'
import CrudToolbar from '@/pages/common/CrudToolbar'
import DialogFormPartner from './DialogFormPartner'
import filter from '@/mixins/filter'
export default {
	components: {CrudToolbar, DialogConfirm, DialogFormPartner},
	mixins: [filter],
	data() {
		return {
			entities: null,
			selectedEntity: null,
			entityDialog: false,
			deleteEntityDialog: false,
			deleteEntitiesDialog: false,
			currentEntity: {},
			selectedEntities: null,
			expandedRows: null,
			filters: {}
		}
	},
	created() {
		this.initGlobalFilter(this.filters);
	},
	mounted() {
		this.initColumnFilters(this.$refs.dt, this.filters);
		PartnerService.getAll().then(data => {
			this.entities = data;
		});
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.currentEntity = {};
			this.entityDialog = true;
		},
		hideDialog() {
			this.entityDialog = false;
			this.submitted = false;
		},
		saveEntity(entity) {
			if (entity.id) {
				this.entities[this.findIndexById(entity.id)] = entity;
					PartnerService.update(entity.id, entity).then((response) => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('partner.updated'), life: 3000});
						this.currentEntity = response;
						this.entityDialog = false;
					})
			}
			else {
				PartnerService.create(entity).then((response) => {
					this.currentEntity = response;
					this.entities.push(response);
					this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('partner.created'), life: 3000});
					this.entityDialog = false;
				})
			}
			this.selectedEntities = []
		},
		editEntity(entity) {
			this.currentEntity = {...entity};
			this.entityDialog = true;
		},

		openConfirmDialog(event) {
				const entityName = this.$t('partner.entityName')
				console.log(entityName)
        this.$confirm.require({
						target: event.currentTarget,
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
            },
            reject: () => {
								this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
						}
        });
    },
		onDeleteEntities() {
			console.log(this.selectedEntities);
			if (Array.isArray(this.selectedEntities) && this.selectedEntities.length == 1) {
				this.confirmDeleteEntity(this.selectedEntities[0]);
			}
		},
		confirmDeleteEntity(entity) {
			this.currentEntity = entity;
			//this.deleteEntityDialog = true;
			const entityName = this.$t('partner.entityName')
			this.$confirm.require({
					message: this.$t('common.confirm_delete_template', [entityName, entity.name]),
					header: this.$t('common.confirm_delete'),
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
							this.deleteEntity()
							//callback to execute when user confirms the action
					},
					reject: () => {}
			});
		},
		deleteEntity() {
			this.entities = this.entities.filter(val => val.id !== this.currentEntity.id);
			PartnerService.delete(this.currentEntity.id).then(() => {
				this.deleteEntityDialog = false;
				this.currentEntity = {};
				this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('partner.deleted'), life: 3000});
			});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.entities.length; i++) {
				if (this.entities[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		deleteSelectedEntities() {
			this.entities = this.entities.filter(val => !this.selectedEntities.includes(val));
			this.deleteEntitiesDialog = false;
			this.selectedEntities = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.multiple_deleted'), life: 3000});
		},
		/*
		initFilters() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
    }
		*/
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
